<template>
    <div class="container">
        <el-form :model="form" label-width="140px" @submit.prevent="submitForm">
            <el-form-item label="管理员账号" prop="username">
                <el-input v-model="form.username" v-if="id==null"></el-input>
                <span v-else>{{form.username}}</span>
            </el-form-item>
            <el-form-item label="真实姓名" prop="realname">
                <el-input v-model="form.realname"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="password" v-if="id==null">
                <el-input v-model="form.password" type="password"></el-input>
            </el-form-item>
            <div style="margin-top:30px; text-align: center">
                <el-button type="primary" native-type="submit" @click.prevent="submitForm">提交</el-button>
                <el-button @click="closePop">关闭</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "AdminForm",
        props: {
            id: {
                type: Number
            },
        },
        data() {
            return {
                form: {
                    username: '',
                    realname: '',
                    password: '',
                },
            }
        },
        watch: {
            id(val, oldVal) {
                if (val != oldVal && val != null) {
                    this.getInfo();
                }
                if (val === null) {
                    this.form = {};
                }
            }
        },
        mounted() {
            if (this.id != null) {
                this.getInfo();
            }
        },
        created() {

        },
        methods: {
            getInfo() {
                this.$get(this.$api.GetAdminInfo, {id: this.id}).then((res) => {
                    if (res.code === 0) {
                        this.form = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            submitForm() {
                if (this.form.realname == '') {
                    this.$message.warning('姓名不能为空');
                    return false;
                }
                let postUrl = this.$api.AddAdmin;
                if (this.id != null) {
                    postUrl = this.$api.EditAdmin;
                } else {
                    if (this.form.username == '') {
                        this.$message.warning('用户名不能为空');
                        return false;
                    }
                    if (this.form.password == '') {
                        this.$message.warning('密码不能为空');
                        return false;
                    }
                }

                this.$post(postUrl, this.form).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.closePop('refresh');
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            closePop(param) {
                this.$emit('closePop', param);
            },
        }
    }
</script>

<style scoped lang="less">

</style>