<template>
    <div class="container">
        <div class="page-title">
            <el-row>
                <el-col :span="18">
                    <h3>用户管理</h3>
                </el-col>
                <el-col :span="6" style="text-align: right;">
                    <el-button type="text" icon="el-icon-plus" @click="addOther">新增管理员</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="tab-wrap">
            <div class="query-box">
                <el-row class="row">
                    <el-col :span="20">
                        <el-form :inline="true" :model="queryParams" @submit.native.prevent>
                            <el-form-item label="关键词" style="margin-bottom: 0">
                                <el-input placeholder="输入用户姓名或账号查询" v-model="queryParams.keys" style="width: 200px;">
                                </el-input>
                            </el-form-item>
                            <el-form-item style="margin-bottom: 0">
                                <el-button type="primary" native-type="submit" @click="searchData"><i class="el-icon-search"></i> 查询</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4" style="text-align: right">

                    </el-col>
                </el-row>
            </div>

            <el-table :data="tableData" ref="multipleTable" v-loading="loading" element-loading-text="加载中..." style="width: 100%" @selection-change="selChange">
                <el-table-column width="50" label="序号">
                    <template slot-scope="scope">
                        {{scope.$index+1}}
                    </template>
                </el-table-column>
                <el-table-column label="管理员账号" prop="username">
                </el-table-column>
                <el-table-column label="姓名" prop="realname">
                </el-table-column>
                <el-table-column label="登录次数" prop="login_counts">
                </el-table-column>
                <el-table-column label="最后登录IP" prop="last_ip">
                </el-table-column>
                <el-table-column label="AUTH验证密钥" prop="auth_secret">
                    <template slot-scope="scope">
                        <el-popover
                                placement="top"
                                :title="scope.row.username+'的验证密钥'"
                                width="200"
                                trigger="click"
                                :content="scope.row.auth_secret">
                            <el-link type="primary" icon="el-icon-view" slot="reference">点击查看</el-link>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="250">
                    <template slot-scope="scope">
                        <span class="row-opt" @click="editData(scope.row.id)"><el-link type="primary" icon="el-icon-edit-outline">修改</el-link></span>
                        <el-divider direction="vertical"></el-divider>
                        <span class="row-opt" @click="resetpass(scope.row)"><el-link type="primary" icon="el-icon-setting">重置密码</el-link></span>
                        <el-divider direction="vertical"></el-divider>
                        <span class="row-opt" @click="deleteData(scope.row)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagenation">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="[10, 20, 50]"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total">
                        </el-pagination>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false">
            <hw-admin-form :id="id" @closePop="closePop"></hw-admin-form>
        </el-dialog>


    </div>
</template>

<script>
    import AdminForm from "../components/pages/AdminForm";

    export default {
        name: "Other",
        components: {
            hwAdminForm: AdminForm,
        },
        data() {
            return {
                activeName: 'other',
                queryParams: {},
                loading: true,
                tableData: [],
                currentPage: 1,
                pageSize: 10,
                total: 0,
                multipleSelection: [],
                addPop: false,
                popTitle: '新增管理员',
                id: null,
            }
        },
        created() {
            this.getListData();
        },
        methods: {
            getListData () {
                this.queryParams.page = this.currentPage;
                this.queryParams.limit = this.pageSize;
                this.$get(this.$api.GetAdminList, this.queryParams).then((res) => {
                    this.loading = false;
                    if (res.code === 0) {
                        this.tableData = res.data.list;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },

            searchData() {
                this.loading = true;
                this.currentPage = 1;
                this.tableData = [];
                this.total = 0;
                this.getListData();
            },
            handleCurrentChange (val) {
                this.currentPage = val
                this.getListData()
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getListData()
            },
            selChange(val) {
                this.multipleSelection = val;
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },

            //选择列表项
            selData(id) {
                let selId = '';
                if (id) { //单个
                    selId = id;
                } else { //批量
                    const length = this.multipleSelection.length;
                    let idsArr = []
                    for (let i = 0; i < length; i++) {
                        idsArr.push(this.multipleSelection[i].id)
                    }
                    selId = idsArr.join();
                }
                return selId;
            },

            //删除列表选项
            deleteData(row) {
                let selId = this.selData(row.id);
                if (selId == '') {
                    this.$message.error('请选择要删除的项');
                    return false;
                }
                this.$confirm('此操作将删除所选项, 是否继续?', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.$post(this.$api.DeleteAdmin, {id: selId})
                        .then((res) => {
                            if (res.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                this.getListData();
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //重置密码
            resetpass(row) {
                this.$confirm("确定要重置 "+row.username+" 的密码吗?密码将会被重置为12345678", '提示', {
                    type: 'warning'
                }).then(() => {
                    this.$post(this.$api.ResetAdminPass, {id: row.id}).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.getListData();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                });
            },
            addOther() {
                this.addPop = true;
                this.popTitle = '新增管理员';
                this.id = null;
            },
            editData (id) {
                this.addPop = true;
                this.popTitle = '编辑管理员';
                this.id = id;
            },
            closePop(param) {
                this.addPop = false;
                this.popTitle = '新增管理员';
                this.id = null;
                if (param == 'refresh') {
                    this.getListData();
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .tab-wrap{
        padding: 0 10px;
    }
    .query-box {
        background: #f6f6f6;
        padding: 10px;
        margin: 10px 0;
    }
    .pagenation{
        margin: 20px auto;
        text-align: center;
    }
    /*/deep/.el-tabs__header{*/
    /*    margin-bottom: 0;*/
    /*}*/

</style>